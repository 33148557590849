$(function () {
    $('#guest_create_user').submit(function (e) {
        e.preventDefault();
        let $form = $(this);
        let formData = new FormData(this);

        $('.help-block').text('');
        $form.closest('.card').addClass('form-loading');

        $.ajax({
            url: $form.attr('action'),
            type: $form.attr('method'),
            headers: {
                'X-CSRF-TOKEN': Laravel.csrfToken
            },
            data: formData,
            processData: false,
            contentType: false,
            cache: false,
        }).done(function (response) {
            window.location.href = response.url;
        }).fail(function (responseXML) {
            if (responseXML.status == 422) {
                let errors = responseXML.responseJSON.errors;
                $.each(errors, function (field, message) {
                    field = field.replace(".", "_");
                    let $formGroup = $('#' + field).closest('.form-group');
                    $formGroup.addClass('has-error');
                    $formGroup.find('.help-block').html(message[0]);
                });
            }
        }).always(function () {
            $form.closest('.card').removeClass('form-loading');
        });
    });

    $(document).on('click', '.guest-form-steps-section .step', function (e) {
        e.stopPropagation();
        let stepId = $(this).attr('data-id'),
            $screenshotPlaceholder = $('.screenshot-placeholder');

        closeScreenshotPopup();
        $(this).addClass('screenshot-active');
        $screenshotPlaceholder.css('display', 'block');
        $screenshotPlaceholder.addClass('active');
        $screenshotPlaceholder.find('.screenshot-' + stepId).addClass('active');
        $('body').addClass('screenshot-show');
        setTimeout(function () {
            // $screenshotPlaceholder.addClass('active');
        }, 200);
    });

    $(document).on('click', 'body.screenshot-show, .screenshot-placeholder .icon-close', function () {
        closeScreenshotPopup();
    });
    $(document).on('click', '.screenshot-placeholder', function (e) {
        e.stopPropagation();
    });

    function closeScreenshotPopup() {
        let $screenshotPlaceholder = $('.screenshot-placeholder');
        $('.guest-form-steps-section .step').removeClass('screenshot-active');

        $screenshotPlaceholder.find('.screenshot').removeClass('active');
        $screenshotPlaceholder.removeClass('active');
        $('body').removeClass('screenshot-show');
        setTimeout(function () {
            // $screenshotPlaceholder.css('display', 'none');
        }, 200);
    }
});